
import { Component } from 'vue-property-decorator';
import { RouteConfig, Route, RawLocation } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';

@Component
export default class ExternalDocument extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const activeItem = this.activeItem;
    if (activeItem && activeItem.$isCreated())
    {
      return [
        {
          title: `${activeItem.$original.documentReference}`,
          icon: `$vuetify.icons.edit`,
          route: `/externaldocument/${activeItem.id}`,
        },
        {
            title: this.$t('fileAttachmentCollection.title').toString(),
            icon: '$vuetify.icons.attachment',
            route: `/externaldocument/${activeItem.id}/fileattachmentcollection`,
          },
      ];
    } else {
      return [
        {
          title: this.$t('form.titleNew').toString(),
          icon: `$vuetify.icons.add`,
          route: `/externaldocument/new`,
        },
      ];
    }
    return [];
  }
}
